const RANK00 = 0 // 無級
const RANK05 = 1 // 5級
const RANK04 = 2 // 4級
const RANK03 = 3 // 3級
const RANK02 = 4 // 2級
const RANK01 = 5 // 1級
const RANK1 = 6 // 初段
const RANK2 = 7 // 2段
const RANK3 = 8 // 3段
const RANK4 = 9 // 4段
const RANK5 = 10 // 5段

const DANS = {
  rank00: RANK00, // 無級
  rank05: RANK05, // 5級
  rank04: RANK04, // 4級
  rank03: RANK03, // 3級
  rank02: RANK02, // 2級
  rank01: RANK01, // 1級
  rank1: RANK1, // 初段
  rank2: RANK2, // 2段
  rank3: RANK3, // 3段
  rank4: RANK4, // 4段
  rank5: RANK5, // 5段
}

export default {
  RANK00,
  RANK05,
  RANK04,
  RANK03,
  RANK02,
  RANK01,
  RANK1,
  RANK2,
  RANK3,
  RANK4,
  RANK5,
  DANS
}