<template>
  <div id="container" v-if="dan">
    <section id="mypage" class="achievement" v-bind:class="{achievement_disabled: !current_user.dan_enabled}">
      <div class="wrap">
        <h2 class="sub_ttl"><span>複眼経済塾認定資格について</span></h2>
        <p class="lead_txt">
          「複眼経済塾認定資格」では、これまでの受講状況に基づいて、学習の進み具合を「5級」から「五段」までの10段階で評価しています。<br>
          昇級・昇段するためには、それぞれの級・段位に設定されている「必要クリア数」を満たす必要があります。また、初段以上の段位では、その段位を維持するために必要な「維持基準」があります。<br>
          <a href="/nintei_shikaku/" class="link_txt mt10">複眼経済塾認定資格くわしくはこちら</a>
        </p>

        <h3 class="min_ttl"><span>現在の達成状況</span></h3>

        <!-- 新しい維持リスト -->
        <div class="keep_box type02">
          <p class="red bold" v-if="dan.dan === 'rank00'">現在「<span>{{ achievementLabel(dan.dan) }}</span>」です。</p>
          <template v-if="dan.expire_month === true">
            <p class="date bold mb20">「{{ achievementLabel(dan.dan) }}」の資格を維持するためには、<span
                class="red">{{ moment(dan.expire_at, 'YYYY/M/DD') }}</span> までに以下の条件を満たす必要があります。</p>
            <div class="keep_list flex">
              <template v-for="(rule, index) in keep_rules">
                <div class="keep_list_box"
                     v-bind:class="{complete: dan.detail.expire[rule.dan].completed_at,
                                    now_challenge: !dan.detail.expire[rule.dan].completed_at}"
                     v-if="dan_value >= rule.dan_value" :key="'rule-keep-' + index">
                  <p>「{{ rule.space }}」を視聴する</p>
                  <div class="status_tag" v-if="dan.detail.expire[rule.dan].completed_at"><span>完了済み</span></div>
                  <div class="status_tag" v-else><span>条件未達成</span></div>
                  <ul class="keep_check_list flex">
                    <li v-for="(m, index) in dan.detail.expire[rule.dan].detail" :key="rule.dan + '-keep-' + index">
                      <router-link :to="{ name: 'SnsPost', params: {post_id: m.id}}"
                                   v-bind:class="{watched: m.clear_at}">
                        <span>{{ m.title }}</span>
                      </router-link>
                    </li>

                    <li v-for="(m, index) in Array(4-dan.detail.expire[rule.dan].detail.length)"
                        :key="rule.dan + '-empty-keep-' + index">
                      <span>---</span>
                    </li>
                  </ul>
                </div>
              </template>
            </div>
          </template>
          <template v-else-if="dan.expire_dan === true">
            <div class="keep_box">
              {{ achievementLabel(current_user.dan) }}には維持基準が設定されています。維持基準は達成した月の翌月月初から表示されます。
            </div>
          </template>
        </div>
        <!-- 新しい維持リストここまで -->

        <div class="clear_list">
          <div class="rank_box flex" v-bind:class="rankClass('rank05')">
            <div class="rank_icon flex">
              <div class="icon"><img src="/common/images/achievement/achievement_rank05.svg" alt="5級">
                <p></p></div><!--<p></p>はタスク？-->
              <h3 class="sp">
                <router-link to="/sns/space/content/4-19">複眼投資入門</router-link>
                <span>（必要クリア数５）</span></h3>
            </div>
            <div class="list">
              <h3 class="pc">
                <router-link to="/sns/space/content/4-19">複眼投資入門</router-link>
                <span>（必要クリア数５）</span></h3>
              <ul class="theme small flex">
                <li class="" v-bind:class="{comp: !dan.detail.rank05.skip && detail.clear_at}"
                    v-for="(detail, index) in dan.detail.rank05.detail" :key="'rank05-' + index">
                  <router-link :to="{ name: 'SnsPost', params: {post_id: detail.sns_post_id}}">
                    <span>{{ detail.title }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="rank_box flex" v-bind:class="rankClass('rank04')">
            <div class="rank_icon flex">
              <div class="icon"><img src="/common/images/achievement/achievement_rank04.svg" alt="4級">
                <p></p></div><!--<p></p>はタスク？-->
              <h3 class="sp">
                <router-link to="/sns/space/content/4-28">5 whys 投資事始</router-link>
                <span>（必要クリア数５）</span></h3>
            </div>
            <div class="list">
              <h3 class="pc">
                <router-link to="/sns/space/content/4-28">5 whys 投資事始</router-link>
                <span>（必要クリア数５）</span></h3>
              <ul class="theme small flex">
                <li class="" v-bind:class="{comp: !dan.detail.rank04.skip && detail.clear_at}"
                    v-for="(detail, index) in dan.detail.rank04.detail" :key="'rank04-' + index">
                  <router-link :to="{ name: 'SnsPost', params: {post_id: detail.sns_post_id}}">
                    <span>{{ detail.title }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="rank_box flex" v-bind:class="rankClass('rank03')">
            <div class="rank_icon flex">
              <div class="icon"><img src="/common/images/achievement/achievement_rank03.svg" alt="3級">
                <p></p></div><!--<p></p>はタスク？-->
              <h3 class="sp">
                <router-link to="/sns/space/content/4-28">5 whys 投資事始</router-link>
                <span>（必要クリア数５）</span></h3>
            </div>
            <div class="list">
              <h3 class="pc">
                <router-link to="/sns/space/content/4-28">5 whys 投資事始</router-link>
                <span>（必要クリア数５）</span></h3>
              <ul class="theme small flex">
                <li class="" v-bind:class="{comp: !dan.detail.rank03.skip && detail.clear_at}"
                    v-for="(detail, index) in dan.detail.rank03.detail" :key="'rank03-' + index">
                  <router-link :to="{ name: 'SnsPost', params: {post_id: detail.sns_post_id}}">
                    <span>{{ detail.title }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <p class="achievement_step_txt" v-if="dan.dan === 'rank03' && current_user.sns_rank === 'rank_preparatory'">
            次の「2級」に挑戦するには本科にアップグレードする必要があります</p>
          <div class="rank_box flex" v-bind:class="rankClass('rank02')">
            <div class="rank_icon flex">
              <div class="icon"><img src="/common/images/achievement/achievement_rank02.svg" alt="2級">
                <p></p></div><!--<p></p>はタスク？-->
              <h3 class="sp">
                <router-link to="/sns/space/content/3-9">塾長週報</router-link>
                <span>（必要クリア数５）</span></h3>
            </div>
            <div class="list">
              <h3 class="pc">
                <router-link to="/sns/space/content/3-9">塾長週報</router-link>
                <span>（必要クリア数５）</span></h3>
              <ul class="theme small flex">
                <li class="" v-bind:class="{comp: !dan.detail.rank02.skip && detail.clear_at}"
                    v-for="(detail, index) in dan.detail.rank02.detail" :key="'rank02-' + index">
                  <router-link :to="{ name: 'SnsPost', params: {post_id: detail.sns_post_id}}">
                    <span>{{ detail.title }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="rank_box flex" v-bind:class="rankClass('rank01')">
            <div class="rank_icon flex">
              <div class="icon"><img src="/common/images/achievement/achievement_rank01.svg" alt="1級">
                <p></p></div><!--<p></p>はタスク？-->
              <h3 class="sp">ワークショップ<span>（必要クリア数３）</span></h3>
            </div>
            <div class="list">
              <h3 class="pc">ワークショップ<span>（必要クリア数３）</span></h3>
              <ul class="theme large flex">
                <li class="" v-bind:class="{comp: !dan.detail.rank01.skip && detail.clear_at}"
                    v-for="(detail, index) in dan.detail.rank01.detail" :key="'rank01-' + index">
                  <router-link
                      :to="{ name: 'WorkshopShow', params: {category: detail.category, id: detail.workshop_id}}">
                    <span>{{ detail.title }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="rank_box flex" v-bind:class="rankClass('rank1')">
            <div class="rank_icon flex">
              <div class="icon"><img src="/common/images/achievement/achievement_rank1.svg" alt="初段">
                <p></p></div><!--<p></p>はタスク？-->
              <h3 class="sp">月例会 動画視聴<span>（必要クリア数４）</span></h3>
            </div>
            <div class="list">
              <h3 class="pc">月例会 動画視聴<span>（必要クリア数４）</span></h3>
              <ul class="theme small flex">
                <li class="" v-bind:class="{comp: !dan.detail.rank1.skip && detail.clear_at}"
                    v-for="(detail, index) in dan.detail.rank1.detail" :key="'rank1-' + index">
                  <router-link :to="{ name: 'SnsPost', params: {post_id: detail.sns_post_id}}">
                    <span>{{ detail.title }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="rank_box flex" v-bind:class="rankClass('rank2')">
            <div class="rank_icon flex">
              <div class="icon"><img src="/common/images/achievement/achievement_rank2.svg" alt="二段">
                <p></p></div><!--<p></p>はタスク？-->
              <h3 class="sp">
                <router-link to="/getsurei">月例会 現地参加</router-link>
                <span>（必要クリア数３）</span></h3>
            </div>
            <div class="list">
              <h3 class="pc">
                <router-link to="/getsurei">月例会 現地参加</router-link>
                <span>（必要クリア数３）</span></h3>
              <ul class="theme large flex">
                <li class="" v-bind:class="{comp: !dan.detail.rank2.skip && detail.clear_at}"
                    v-for="(detail, index) in dan.detail.rank2.detail" :key="'rank2-' + index">
                  <router-link :to="{ name: 'GetsureiShow', params: {id: detail.id}}">
                    <span>{{ detail.title }}
                      <template v-if="detail.open_date"><br>{{ moment(detail.open_date, 'YYYY/MM/DD') }}</template>
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="rank_box flex" v-bind:class="rankClass('rank3')">
            <div class="rank_icon flex">
              <div class="icon"><img src="/common/images/achievement/achievement_rank3.svg" alt="三段">
                <p></p></div><!--<p></p>はタスク？-->
              <h3 class="sp">
                <router-link to="/camp_camp">複眼合宿</router-link>
                <span>（必要クリア数１）</span></h3>
            </div>
            <div class="list">
              <h3 class="pc">
                <router-link to="/camp_camp">複眼合宿</router-link>
                <span>（必要クリア数１）</span></h3>
              <ul class="theme large sp_full flex">
                <li class="" v-bind:class="{comp: !dan.detail.rank3.skip && detail.clear_at}"
                    v-for="(detail, index) in dan.detail.rank3.detail" :key="'rank3-' + index">
                  <router-link
                      :to="{ name: 'WorkshopShow', params: {category: detail.category, id: detail.workshop_id}}">
                    <span>{{ detail.title }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <p class="achievement_step_txt" v-if="dan.dan === 'rank3' && current_user.sns_rank !== 'rank_regular_plus'">
            次の「四段」に挑戦するには本科プラスにアップグレードする必要があります</p>
          <div class="rank_box flex" v-bind:class="rankClass('rank4')">
            <div class="rank_icon flex">
              <div class="icon"><img src="/common/images/achievement/achievement_rank4.svg" alt="四段">
                <p></p></div><!--<p></p>はタスク？-->
              <h3 class="sp">
                <router-link to="/getsurei">前夜祭</router-link>
                <span>（必要クリア数５）</span></h3>
            </div>
            <div class="list">
              <h3 class="pc">
                <router-link to="/getsurei">前夜祭</router-link>
                <span>（必要クリア数５）</span></h3>
              <ul class="theme large flex">
                <li class="" v-bind:class="{comp: !dan.detail.rank4.skip && detail.clear_at}"
                    v-for="(detail, index) in dan.detail.rank4.detail" :key="'rank4-' + index">
                  <router-link :to="{ name: 'GetsureiShow', params: {id: detail.id}}">
                    <span>{{ detail.title }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="rank_box flex" v-bind:class="rankClass('rank5')">
            <div class="rank_icon flex">
              <div class="icon"><img src="/common/images/achievement/achievement_rank5.svg" alt="五段">
                <p></p></div><!--<p></p>はタスク？-->
              <h3 class="sp">
                <router-link to="/camp_tour">複眼ツアー</router-link>
                <span>（必要クリア数２）</span></h3>
            </div>
            <div class="list">
              <h3 class="pc">
                <router-link to="/camp_tour">複眼ツアー</router-link>
                <span>（必要クリア数２）</span></h3>
              <ul class="theme large flex">
                <li class="" v-bind:class="{comp: !dan.detail.rank5.skip && detail.clear_at}"
                    v-for="(detail, index) in dan.detail.rank5.detail" :key="'rank5-' + index">
                  <router-link
                      :to="{ name: 'WorkshopShow', params: {category: detail.category, id: detail.workshop_id}}">
                    <span>{{ detail.title }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="button_wrap bdr_top">
          <router-link :to="{name: 'UsersCustomerAccount'}" class="submit arrow back skeleton light large">
            マイページに戻る
          </router-link>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import Libraries from '@/components/Libraries'
import Dans from '@/const/Achievements.js'

export default {
  mixins: [Libraries],
  data() {
    return {
      dan: null,
      dan_value: null,
      keep_rules: [
        {
          dan: 'rank1', dan_value: Dans.RANK1, space: '5 whys 投資事始'
        },
        {
          dan: 'rank2', dan_value: Dans.RANK2, space: '塾長週報'
        }
      ]
    }
  },
  mounted() {
    if (this.current_user.rank === 'rank_student_month') {
      // マイページへ
      this.$router.push({name: 'UsersCustomerAccount'})
    } else {
      this.fetchData()
    }
  },
  methods: {
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}users/achievement.json`)
          .then(response => {
            this.dan = response.data.achievement
            this.dan_value = Dans.DANS[this.dan.dan]
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    }
    ,
    rankClass(checkRank) {
      const now_index = this.dan_array.indexOf(this.dan.dan)
      const check_index = this.dan_array.indexOf(checkRank)
      let addClass = []
      if (now_index === check_index) {
        addClass.push('now_rank')
      } else if (now_index + 1 === check_index) {
        addClass.push('challenge')
      } else if (now_index + 1 <= check_index) {
        addClass.push('no_clear')
      }

      if (this.dan.detail[checkRank].skip) {
        addClass.push('skip')
      }

      return addClass
    },
  }
}
</script>
